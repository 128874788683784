<template>
  <docs-layout>
    <div class="space-y-4 pb-10 w-full text-left">
      <h3 class="text-xl font-bold">Default</h3>
      <p>
        Slider Menu visibility can be control via v-model. This is the
        recommended way
      </p>
      <asom-button @click="toggleSlider1(true)" text="Show Slider" />
      <asom-slide-menu v-model="showSlider1" title="Default Slider">
        <p class="text-sm text-gray-500">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Orci nulla pellentesque dignissim enim sit. Ultricies integer quis auctor elit sed. Amet est placerat in egestas erat imperdiet sed euismod nisi. Vulputate enim nulla aliquet porttitor. Aliquam ut porttitor leo a diam sollicitudin. Ut ornare lectus sit amet est. Enim neque volutpat ac tincidunt vitae semper quis lectus. Pretium viverra suspendisse potenti nullam ac tortor vitae purus faucibus. Sed viverra tellus in hac habitasse platea dictumst vestibulum. Neque ornare aenean euismod elementum nisi quis. Adipiscing elit pellentesque habitant morbi tristique senectus et netus. Facilisi nullam vehicula ipsum a arcu cursus vitae.

Dolor magna eget est lorem ipsum dolor sit amet consectetur. Aliquam nulla facilisi cras fermentum odio eu feugiat pretium nibh. Quis risus sed vulputate odio ut enim. Viverra adipiscing at in tellus. In aliquam sem fringilla ut morbi tincidunt augue interdum velit. Erat pellentesque adipiscing commodo elit at. Ut porttitor leo a diam sollicitudin tempor id eu. Leo in vitae turpis massa sed elementum. Turpis nunc eget lorem dolor sed viverra ipsum nunc aliquet. Sagittis nisl rhoncus mattis rhoncus urna neque viverra. In tellus integer feugiat scelerisque varius morbi enim nunc faucibus. Adipiscing at in tellus integer. Ullamcorper morbi tincidunt ornare massa. Ornare suspendisse sed nisi lacus sed viverra tellus in.

Eget lorem dolor sed viverra. Justo nec ultrices dui sapien eget mi proin. Venenatis cras sed felis eget velit. Libero id faucibus nisl tincidunt eget. Platea dictumst quisque sagittis purus. Tincidunt tortor aliquam nulla facilisi cras fermentum odio. Ut etiam sit amet nisl purus in mollis. Proin sed libero enim sed faucibus turpis. Netus et malesuada fames ac turpis egestas integer. Fermentum posuere urna nec tincidunt praesent semper feugiat nibh sed. In eu mi bibendum neque egestas congue quisque. Mollis nunc sed id semper risus in.

Morbi blandit cursus risus at ultrices mi tempus imperdiet. Iaculis urna id volutpat lacus. Sed id semper risus in hendrerit. Aliquam id diam maecenas ultricies mi. Vestibulum mattis ullamcorper velit sed ullamcorper. Duis ultricies lacus sed turpis tincidunt. Convallis a cras semper auctor neque vitae tempus quam pellentesque. Dignissim sodales ut eu sem. Aliquet nec ullamcorper sit amet. Iaculis nunc sed augue lacus viverra vitae congue.

Pretium viverra suspendisse potenti nullam ac tortor vitae. Pulvinar proin gravida hendrerit lectus a. Orci a scelerisque purus semper. Morbi tincidunt augue interdum velit euismod in pellentesque. Arcu cursus vitae congue mauris. Tempor commodo ullamcorper a lacus. In aliquam sem fringilla ut morbi. Sapien faucibus et molestie ac. Nec dui nunc mattis enim. Elementum facilisis leo vel fringilla est. Amet purus gravida quis blandit turpis cursus in hac. Ultrices in iaculis nunc sed augue. Platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Aliquam eleifend mi in nulla posuere sollicitudin. Ullamcorper a lacus vestibulum sed arcu non odio euismod lacinia. Scelerisque felis imperdiet proin fermentum leo vel orci porta. At risus viverra adipiscing at in tellus integer. Fermentum leo vel orci porta non pulvinar neque laoreet.

Nec feugiat in fermentum posuere. Sapien et ligula ullamcorper malesuada proin libero nunc consequat. Quis lectus nulla at volutpat diam ut venenatis. Pretium fusce id velit ut. Nibh praesent tristique magna sit amet purus gravida. Varius morbi enim nunc faucibus a pellentesque sit amet porttitor. Amet venenatis urna cursus eget nunc. Iaculis urna id volutpat lacus laoreet. Phasellus egestas tellus rutrum tellus pellentesque eu. Fermentum leo vel orci porta non pulvinar neque laoreet. Aliquam sem et tortor consequat id porta. Aliquet enim tortor at auctor.

Sollicitudin tempor id eu nisl nunc mi ipsum faucibus. Diam ut venenatis tellus in metus. Cras fermentum odio eu feugiat. Amet nulla facilisi morbi tempus iaculis urna id volutpat. Maecenas sed enim ut sem viverra aliquet eget sit. Sed vulputate odio ut enim blandit volutpat maecenas. Faucibus pulvinar elementum integer enim neque. Ut enim blandit volutpat maecenas volutpat. Dignissim suspendisse in est ante in nibh. Risus feugiat in ante metus dictum at tempor. Sit amet justo donec enim diam. Nunc sed velit dignissim sodales ut eu sem integer vitae. Scelerisque eleifend donec pretium vulputate sapien nec sagittis. Ut placerat orci nulla pellentesque dignissim enim sit amet. Pellentesque sit amet porttitor eget dolor morbi. Orci sagittis eu volutpat odio facilisis. Scelerisque viverra mauris in aliquam sem fringilla. A diam sollicitudin tempor id eu nisl nunc.

Vivamus arcu felis bibendum ut tristique et egestas quis ipsum. Amet tellus cras adipiscing enim eu turpis. Faucibus pulvinar elementum integer enim neque volutpat ac. Fringilla ut morbi tincidunt augue interdum velit. Sodales ut etiam sit amet nisl purus. Nunc sed velit dignissim sodales ut eu sem integer. Fermentum leo vel orci porta non pulvinar. Arcu non odio euismod lacinia at quis risus sed vulputate. Consectetur libero id faucibus nisl tincidunt eget nullam non. Pharetra et ultrices neque ornare aenean euismod elementum nisi. Magna fermentum iaculis eu non. Eget mi proin sed libero enim sed. Interdum velit laoreet id donec ultrices tincidunt arcu non sodales. Porttitor eget dolor morbi non arcu risus quis varius. Cursus risus at ultrices mi tempus imperdiet nulla. Feugiat pretium nibh ipsum consequat nisl. Lectus sit amet est placerat in egestas erat imperdiet.

Massa ultricies mi quis hendrerit dolor magna eget est lorem. Eu facilisis sed odio morbi quis commodo odio aenean sed. Cursus turpis massa tincidunt dui ut ornare lectus sit amet. Ornare aenean euismod elementum nisi quis eleifend quam. Augue interdum velit euismod in pellentesque massa placerat. Nunc sed blandit libero volutpat sed cras ornare arcu dui. Volutpat odio facilisis mauris sit amet massa vitae. Vitae turpis massa sed elementum. Purus viverra accumsan in nisl nisi scelerisque. Tincidunt lobortis feugiat vivamus at augue eget. Ullamcorper eget nulla facilisi etiam. Aliquam sem fringilla ut morbi tincidunt augue interdum velit. Ultricies mi eget mauris pharetra et ultrices neque ornare. Bibendum arcu vitae elementum curabitur vitae. Sit amet luctus venenatis lectus magna fringilla urna porttitor.

Turpis nunc eget lorem dolor. Suspendisse faucibus interdum posuere lorem ipsum dolor sit. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus. Arcu vitae elementum curabitur vitae nunc. Nec ultrices dui sapien eget. Convallis convallis tellus id interdum velit laoreet. Fringilla ut morbi tincidunt augue. Quis lectus nulla at volutpat diam ut venenatis. Facilisis sed odio morbi quis. At varius vel pharetra vel turpis nunc. Vitae justo eget magna fermentum. A condimentum vitae sapien pellentesque habitant. Velit ut tortor pretium viverra suspendisse potenti nullam. Non arcu risus quis varius quam quisque id. Tempor id eu nisl nunc mi. Sed egestas egestas fringilla phasellus faucibus scelerisque. Bibendum ut tristique et egestas.

Gravida arcu ac tortor dignissim convallis aenean et. Nisl condimentum id venenatis a. Ut etiam sit amet nisl. Adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna. Feugiat sed lectus vestibulum mattis ullamcorper. Lorem ipsum dolor sit amet consectetur adipiscing. Eu scelerisque felis imperdiet proin. Lacus viverra vitae congue eu consequat ac felis donec et. A pellentesque sit amet porttitor eget dolor morbi. Nunc mattis enim ut tellus elementum sagittis vitae. Habitant morbi tristique senectus et netus et malesuada fames ac. At ultrices mi tempus imperdiet. Nisl pretium fusce id velit ut tortor pretium. Elit pellentesque habitant morbi tristique senectus et netus et malesuada. Ac ut consequat semper viverra nam. Faucibus pulvinar elementum integer enim. Malesuada fames ac turpis egestas sed tempus urna.

Fringilla ut morbi tincidunt augue interdum velit euismod. Lobortis feugiat vivamus at augue. Sed faucibus turpis in eu mi bibendum. Turpis tincidunt id aliquet risus feugiat in ante metus dictum. Sagittis orci a scelerisque purus semper eget. Nec ultrices dui sapien eget. Porttitor lacus luctus accumsan tortor. Ornare quam viverra orci sagittis. Dictum varius duis at consectetur lorem donec. Integer vitae justo eget magna fermentum iaculis. Eget arcu dictum varius duis at consectetur. Varius quam quisque id diam vel. Non blandit massa enim nec dui nunc. Amet commodo nulla facilisi nullam vehicula.

Ultrices mi tempus imperdiet nulla malesuada pellentesque elit. Vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique. Eget felis eget nunc lobortis mattis aliquam. Venenatis a condimentum vitae sapien pellentesque habitant morbi. Amet cursus sit amet dictum sit amet justo donec enim. Fames ac turpis egestas integer eget aliquet nibh praesent tristique. Tortor at risus viverra adipiscing at in tellus integer. Duis tristique sollicitudin nibh sit amet commodo nulla facilisi. Odio morbi quis commodo odio aenean sed adipiscing diam. At ultrices mi tempus imperdiet nulla malesuada. Lacinia quis vel eros donec ac odio. Lobortis scelerisque fermentum dui faucibus in ornare. Ullamcorper sit amet risus nullam eget. Vulputate odio ut enim blandit volutpat. Tristique senectus et netus et malesuada fames ac turpis. Eleifend mi in nulla posuere sollicitudin aliquam.

Mattis vulputate enim nulla aliquet. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque. Erat velit scelerisque in dictum non consectetur a erat nam. Morbi non arcu risus quis varius quam quisque id diam. Nisl nunc mi ipsum faucibus vitae aliquet nec ullamcorper. Odio facilisis mauris sit amet massa vitae tortor condimentum. Ipsum a arcu cursus vitae. Id diam maecenas ultricies mi eget mauris pharetra et. Nunc scelerisque viverra mauris in aliquam sem fringilla ut. Eu augue ut lectus arcu. Cras fermentum odio eu feugiat pretium. Nibh mauris cursus mattis molestie a. Ornare massa eget egestas purus viverra accumsan in nisl nisi. Dictum at tempor commodo ullamcorper a lacus vestibulum sed. Dictumst quisque sagittis purus sit amet. Purus gravida quis blandit turpis cursus in hac habitasse. Congue eu consequat ac felis donec et odio pellentesque. Eros in cursus turpis massa tincidunt dui ut. Mauris ultrices eros in cursus turpis massa tincidunt dui ut.

Et magnis dis parturient montes. Sed elementum tempus egestas sed sed. Pellentesque elit eget gravida cum sociis. Quis imperdiet massa tincidunt nunc pulvinar sapien et. Volutpat est velit egestas dui id ornare arcu odio ut. Lacus vel facilisis volutpat est velit egestas dui id. Orci sagittis eu volutpat odio facilisis mauris sit amet. Tristique risus nec feugiat in fermentum posuere urna nec tincidunt. Ut lectus arcu bibendum at varius vel pharetra vel. Sit amet porttitor eget dolor. Id velit ut tortor pretium viverra suspendisse. Sit amet cursus sit amet. Dolor sed viverra ipsum nunc aliquet bibendum enim. Sed libero enim sed faucibus turpis in eu mi bibendum. Ullamcorper malesuada proin libero nunc consequat interdum varius. Sed viverra ipsum nunc aliquet bibendum. Nisi scelerisque eu ultrices vitae auctor eu augue ut lectus. Tortor pretium viverra suspendisse potenti nullam ac. Accumsan sit amet nulla facilisi morbi tempus iaculis urna.

Amet facilisis magna etiam tempor orci eu lobortis elementum nibh. Egestas sed sed risus pretium. Diam sollicitudin tempor id eu. Neque ornare aenean euismod elementum nisi quis eleifend quam. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Netus et malesuada fames ac. In nibh mauris cursus mattis molestie a iaculis at erat. Cursus mattis molestie a iaculis at erat pellentesque adipiscing. Ac tortor vitae purus faucibus. Potenti nullam ac tortor vitae purus. A iaculis at erat pellentesque adipiscing commodo elit at imperdiet. Orci a scelerisque purus semper. Tellus rutrum tellus pellentesque eu tincidunt tortor.

Dui nunc mattis enim ut tellus elementum sagittis. Purus ut faucibus pulvinar elementum integer enim. Pellentesque habitant morbi tristique senectus et netus. In vitae turpis massa sed elementum tempus egestas sed sed. Cras fermentum odio eu feugiat pretium nibh. In hac habitasse platea dictumst quisque. Tincidunt arcu non sodales neque sodales ut etiam sit. Sit amet justo donec enim diam. Mattis aliquam faucibus purus in massa tempor nec. Nulla facilisi etiam dignissim diam quis enim lobortis scelerisque. Auctor urna nunc id cursus metus aliquam. Tortor condimentum lacinia quis vel eros donec ac odio. In ornare quam viverra orci sagittis eu volutpat. Condimentum vitae sapien pellentesque habitant morbi tristique senectus et. Enim eu turpis egestas pretium aenean pharetra. Donec ultrices tincidunt arcu non.

Tellus at urna condimentum mattis pellentesque id nibh tortor id. Nunc pulvinar sapien et ligula ullamcorper. Amet nisl purus in mollis nunc sed id semper. Ut sem nulla pharetra diam sit amet nisl suscipit. Auctor elit sed vulputate mi sit. Mauris vitae ultricies leo integer malesuada nunc. At augue eget arcu dictum varius duis. Congue mauris rhoncus aenean vel. Hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit. Aliquam nulla facilisi cras fermentum odio eu feugiat pretium nibh. Nec feugiat nisl pretium fusce. Rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt lobortis. Vitae elementum curabitur vitae nunc sed velit dignissim. Semper risus in hendrerit gravida rutrum quisque non tellus orci. Volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque.

Sit amet mattis vulputate enim. Gravida quis blandit turpis cursus in hac habitasse platea. Fermentum et sollicitudin ac orci phasellus egestas tellus rutrum. Nisi est sit amet facilisis magna etiam. Lacus viverra vitae congue eu. Pellentesque sit amet porttitor eget dolor morbi non arcu risus. Pulvinar mattis nunc sed blandit. Nisl tincidunt eget nullam non nisi est sit amet. Pellentesque id nibh tortor id. Eget nunc lobortis mattis aliquam. Nulla aliquet porttitor lacus luctus accumsan. Arcu vitae elementum curabitur vitae nunc. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla. Integer enim neque volutpat ac tincidunt vitae semper. Nec ultrices dui sapien eget mi proin sed libero. Elit at imperdiet dui accumsan sit amet nulla facilisi morbi. Rutrum quisque non tellus orci ac auctor. Viverra maecenas accumsan lacus vel facilisis volutpat est velit. Fermentum posuere urna nec tincidunt praesent semper. Id venenatis a condimentum vitae sapien pellentesque habitant.

Ultrices eros in cursus turpis. Iaculis eu non diam phasellus vestibulum lorem sed risus. Etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus. Viverra nam libero justo laoreet sit amet cursus sit. Proin fermentum leo vel orci. Urna molestie at elementum eu facilisis sed. Eget aliquet nibh praesent tristique magna sit amet. Eros donec ac odio tempor. Ac tortor dignissim convallis aenean et tortor at risus. Egestas dui id ornare arcu. Ut tortor pretium viverra suspendisse potenti nullam. Ac ut consequat semper viverra nam libero justo. Lectus magna fringilla urna porttitor rhoncus dolor purus. Sed libero enim sed faucibus turpis. Vel facilisis volutpat est velit egestas dui. Tempor nec feugiat nisl pretium fusce id. Interdum varius sit amet mattis vulputate. Aliquet sagittis id consectetur purus. Sed euismod nisi porta lorem mollis aliquam ut porttitor leo. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum curabitur.

Eros in cursus turpis massa tincidunt dui ut ornare lectus. Pharetra diam sit amet nisl suscipit. Mattis vulputate enim nulla aliquet porttitor. Egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Ipsum a arcu cursus vitae. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum. Tincidunt praesent semper feugiat nibh sed pulvinar proin gravida. Amet commodo nulla facilisi nullam vehicula ipsum. Ut faucibus pulvinar elementum integer. Amet nisl suscipit adipiscing bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet non curabitur gravida arcu. Urna duis convallis convallis tellus id interdum. Dapibus ultrices in iaculis nunc sed augue. Ut porttitor leo a diam sollicitudin tempor id eu. Laoreet sit amet cursus sit amet. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque. Elementum eu facilisis sed odio morbi quis commodo. In mollis nunc sed id semper risus in. Neque vitae tempus quam pellentesque nec nam.

Pulvinar mattis nunc sed blandit libero. Tortor at risus viverra adipiscing at in tellus integer. Sed felis eget velit aliquet sagittis id. Sed ullamcorper morbi tincidunt ornare. Cursus vitae congue mauris rhoncus. Amet porttitor eget dolor morbi non arcu risus quis. Viverra ipsum nunc aliquet bibendum. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida. Cursus metus aliquam eleifend mi in nulla posuere sollicitudin. Commodo viverra maecenas accumsan lacus vel facilisis volutpat est velit. Cursus turpis massa tincidunt dui ut ornare lectus sit amet.

Elementum nisi quis eleifend quam adipiscing. Mattis aliquam faucibus purus in. Cras tincidunt lobortis feugiat vivamus at augue eget. Sed euismod nisi porta lorem mollis aliquam ut porttitor. Dolor sit amet consectetur adipiscing elit duis tristique sollicitudin. Tortor posuere ac ut consequat semper viverra nam. Dictum fusce ut placerat orci nulla pellentesque. Turpis cursus in hac habitasse platea. Massa massa ultricies mi quis hendrerit dolor magna eget est. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Viverra aliquet eget sit amet tellus cras adipiscing enim. Orci eu lobortis elementum nibh tellus molestie nunc.

Tortor id aliquet lectus proin nibh nisl condimentum id venenatis. Volutpat sed cras ornare arcu dui vivamus arcu felis. A diam sollicitudin tempor id eu nisl nunc mi ipsum. Ut tortor pretium viverra suspendisse potenti. Proin nibh nisl condimentum id venenatis a condimentum vitae sapien. Eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim. Pharetra et ultrices neque ornare aenean euismod elementum nisi. Amet tellus cras adipiscing enim eu turpis egestas. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant. Commodo ullamcorper a lacus vestibulum sed. Id neque aliquam vestibulum morbi blandit cursus risus at. Morbi enim nunc faucibus a.

Aenean pharetra magna ac placerat vestibulum lectus mauris. A lacus vestibulum sed arcu non odio. Semper auctor neque vitae tempus quam pellentesque nec. Pulvinar sapien et ligula ullamcorper malesuada proin libero. Scelerisque in dictum non consectetur a erat nam. Eu volutpat odio facilisis mauris sit amet. Quam viverra orci sagittis eu volutpat odio facilisis. Venenatis urna cursus eget nunc scelerisque viverra mauris in. Sit amet porttitor eget dolor morbi. Quam quisque id diam vel quam elementum. Non odio euismod lacinia at quis risus. Nisl nisi scelerisque eu ultrices vitae auctor eu augue. Dui sapien eget mi proin. Elementum eu facilisis sed odio. Eleifend mi in nulla posuere. Sagittis purus sit amet volutpat consequat mauris nunc congue nisi. Felis eget nunc lobortis mattis aliquam. Ullamcorper velit sed ullamcorper morbi. Viverra aliquet eget sit amet tellus.

Sociis natoque penatibus et magnis dis parturient montes nascetur. In nibh mauris cursus mattis molestie a iaculis at erat. Blandit libero volutpat sed cras ornare arcu dui vivamus. Scelerisque in dictum non consectetur a erat nam at lectus. Scelerisque in dictum non consectetur a erat nam at. Erat nam at lectus urna duis convallis convallis. A condimentum vitae sapien pellentesque habitant morbi tristique senectus. Neque ornare aenean euismod elementum nisi quis eleifend. Nibh tellus molestie nunc non blandit massa. Sodales ut eu sem integer. Lectus proin nibh nisl condimentum id venenatis a condimentum. Nisl nisi scelerisque eu ultrices vitae auctor eu. Facilisis magna etiam tempor orci eu lobortis elementum nibh tellus. Mi eget mauris pharetra et ultrices neque ornare aenean. Quam id leo in vitae turpis massa sed elementum. Quis enim lobortis scelerisque fermentum dui faucibus.

Semper quis lectus nulla at volutpat. In pellentesque massa placerat duis ultricies lacus sed. Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Eu ultrices vitae auctor eu augue. Nascetur ridiculus mus mauris vitae ultricies leo. Vitae et leo duis ut diam. Est lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque. At elementum eu facilisis sed odio morbi quis commodo odio. Proin libero nunc consequat interdum varius sit. Mi eget mauris pharetra et ultrices neque ornare. Felis eget nunc lobortis mattis aliquam faucibus purus. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non. Faucibus turpis in eu mi. Imperdiet proin fermentum leo vel orci porta. Faucibus et molestie ac feugiat sed lectus.

Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Non curabitur gravida arcu ac tortor. Risus ultricies tristique nulla aliquet enim tortor at auctor urna. Platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Gravida dictum fusce ut placerat orci nulla pellentesque. Dui sapien eget mi proin sed libero enim sed faucibus. Adipiscing diam donec adipiscing tristique risus nec feugiat in. Ultrices gravida dictum fusce ut placerat orci nulla pellentesque. Tempus iaculis urna id volutpat lacus laoreet. Vivamus arcu felis bibendum ut. Ultrices mi tempus imperdiet nulla malesuada pellentesque elit. Nec sagittis aliquam malesuada bibendum arcu vitae elementum curabitur. Nunc aliquet bibendum enim facilisis gravida neque convallis. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Semper quis lectus nulla at volutpat diam ut venenatis. Vulputate dignissim suspendisse in est ante.

Non curabitur gravida arcu ac. Molestie at elementum eu facilisis sed odio morbi quis commodo. Curabitur vitae nunc sed velit. Mattis molestie a iaculis at erat pellentesque adipiscing commodo. Elementum eu facilisis sed odio morbi quis. Hendrerit dolor magna eget est lorem ipsum dolor sit amet. Suspendisse interdum consectetur libero id faucibus nisl tincidunt eget nullam. Sit amet luctus venenatis lectus. In tellus integer feugiat scelerisque varius. Nisi scelerisque eu ultrices vitae auctor. Gravida neque convallis a cras semper auctor neque vitae. Bibendum at varius vel pharetra. Vivamus arcu felis bibendum ut tristique et egestas quis. Ullamcorper malesuada proin libero nunc consequat interdum varius sit.

Pulvinar pellentesque habitant morbi tristique senectus et netus et. Vitae aliquet nec ullamcorper sit. Est velit egestas dui id ornare arcu. Dui ut ornare lectus sit amet est placerat. Nulla pellentesque dignissim enim sit amet. Sem integer vitae justo eget magna fermentum iaculis eu non. Tempus imperdiet nulla malesuada pellentesque elit eget. Sit amet est placerat in egestas erat imperdiet. Lectus mauris ultrices eros in cursus turpis massa tincidunt dui. Tincidunt lobortis feugiat vivamus at augue eget arcu dictum varius. Nec nam aliquam sem et tortor consequat id. Venenatis lectus magna fringilla urna porttitor. Lorem dolor sed viverra ipsum. Mollis nunc sed id semper risus. Aliquam malesuada bibendum arcu vitae. Venenatis a condimentum vitae sapien pellentesque.

Leo a diam sollicitudin tempor id. At imperdiet dui accumsan sit amet nulla. Viverra accumsan in nisl nisi scelerisque eu ultrices vitae. Praesent elementum facilisis leo vel fringilla est ullamcorper eget nulla. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt. Vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra. Massa tempor nec feugiat nisl pretium fusce id. Amet nisl suscipit adipiscing bibendum est ultricies integer. Urna nec tincidunt praesent semper feugiat nibh sed. Dis parturient montes nascetur ridiculus. Diam vel quam elementum pulvinar etiam non. Praesent elementum facilisis leo vel fringilla est ullamcorper eget nulla. Quisque sagittis purus sit amet volutpat consequat mauris nunc congue. Hendrerit dolor magna eget est lorem ipsum. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla. Enim neque volutpat ac tincidunt vitae semper quis lectus nulla. Sagittis eu volutpat odio facilisis mauris sit amet massa vitae. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia.

Elit pellentesque habitant morbi tristique. Mi quis hendrerit dolor magna eget est lorem ipsum dolor. Turpis egestas pretium aenean pharetra magna ac placerat vestibulum. Malesuada pellentesque elit eget gravida. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Ut tortor pretium viverra suspendisse potenti nullam ac. Ac tortor dignissim convallis aenean et. At lectus urna duis convallis. Cras adipiscing enim eu turpis egestas pretium. Velit sed ullamcorper morbi tincidunt ornare massa.

Proin nibh nisl condimentum id. Facilisi morbi tempus iaculis urna id. Vitae justo eget magna fermentum iaculis eu non diam phasellus. Eget duis at tellus at. Vitae elementum curabitur vitae nunc sed velit. Ultrices gravida dictum fusce ut placerat orci nulla pellentesque. Lobortis feugiat vivamus at augue eget arcu dictum varius. Morbi tempus iaculis urna id volutpat lacus laoreet non curabitur. Tempus egestas sed sed risus pretium quam vulputate dignissim. Mauris augue neque gravida in fermentum et sollicitudin ac orci. Bibendum ut tristique et egestas. Luctus accumsan tortor posuere ac ut.

Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat. Sapien eget mi proin sed. In ornare quam viverra orci. Elit sed vulputate mi sit amet. Nibh sit amet commodo nulla. Sagittis id consectetur purus ut faucibus pulvinar. Molestie ac feugiat sed lectus. Arcu odio ut sem nulla. Egestas sed tempus urna et pharetra pharetra massa massa ultricies. Arcu felis bibendum ut tristique. Nunc pulvinar sapien et ligula. Habitant morbi tristique senectus et netus et malesuada fames ac. Commodo odio aenean sed adipiscing diam donec adipiscing. Facilisis magna etiam tempor orci eu.

Lorem dolor sed viverra ipsum. In dictum non consectetur a erat nam at lectus urna. Volutpat ac tincidunt vitae semper quis. Venenatis urna cursus eget nunc scelerisque viverra. Imperdiet nulla malesuada pellentesque elit eget gravida cum. Viverra ipsum nunc aliquet bibendum enim facilisis gravida. Turpis in eu mi bibendum neque egestas congue. Risus ultricies tristique nulla aliquet enim tortor at auctor. Sagittis purus sit amet volutpat consequat. Suspendisse sed nisi lacus sed viverra tellus in hac habitasse. Netus et malesuada fames ac turpis. Est sit amet facilisis magna etiam. Gravida neque convallis a cras semper.

Pharetra magna ac placerat vestibulum lectus mauris ultrices eros in. Amet purus gravida quis blandit. Enim ut sem viverra aliquet eget sit amet. Duis ut diam quam nulla. Ullamcorper a lacus vestibulum sed. Duis ut diam quam nulla porttitor massa. Penatibus et magnis dis parturient montes. Lectus mauris ultrices eros in cursus turpis. Nunc sed velit dignissim sodales ut eu sem integer vitae. Tellus mauris a diam maecenas sed enim. Pulvinar etiam non quam lacus suspendisse faucibus. Morbi leo urna molestie at elementum eu facilisis. Sapien et ligula ullamcorper malesuada proin. Vehicula ipsum a arcu cursus vitae. Pharetra et ultrices neque ornare aenean euismod elementum nisi.

Pellentesque pulvinar pellentesque habitant morbi. Id ornare arcu odio ut. Netus et malesuada fames ac turpis egestas. Consequat ac felis donec et odio pellentesque diam volutpat commodo. Massa placerat duis ultricies lacus sed turpis. Sed blandit libero volutpat sed cras ornare. Aliquam nulla facilisi cras fermentum odio. Nisi est sit amet facilisis magna etiam. Donec et odio pellentesque diam volutpat commodo sed. Dolor magna eget est lorem ipsum. Amet consectetur adipiscing elit ut.

Nunc congue nisi vitae suscipit. Purus faucibus ornare suspendisse sed nisi lacus sed viverra. Nibh venenatis cras sed felis eget velit aliquet sagittis. Elit eget gravida cum sociis natoque penatibus. Ut placerat orci nulla pellentesque dignissim enim sit. Non arcu risus quis varius quam quisque id diam. Odio facilisis mauris sit amet. Dui faucibus in ornare quam viverra orci sagittis. Amet purus gravida quis blandit. Tempus imperdiet nulla malesuada pellentesque elit eget. Cursus in hac habitasse platea dictumst. Condimentum id venenatis a condimentum vitae sapien.

Mauris nunc congue nisi vitae suscipit tellus mauris a diam. Magna fringilla urna porttitor rhoncus. Proin nibh nisl condimentum id venenatis a condimentum vitae sapien. Orci sagittis eu volutpat odio. Id aliquet lectus proin nibh nisl condimentum. Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Cras tincidunt lobortis feugiat vivamus at. At tempor commodo ullamcorper a lacus vestibulum sed. Odio morbi quis commodo odio. Enim ut tellus elementum sagittis vitae et leo. Urna id volutpat lacus laoreet. Augue neque gravida in fermentum et. Id aliquet risus feugiat in ante metus. Semper auctor neque vitae tempus quam pellentesque. Tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed. Cras sed felis eget velit aliquet sagittis id. Vel risus commodo viverra maecenas accumsan lacus vel. Congue quisque egestas diam in arcu.

Imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper. Massa ultricies mi quis hendrerit dolor magna eget est. Vestibulum sed arcu non odio euismod lacinia. Eget nullam non nisi est sit amet. Fusce ut placerat orci nulla. Diam volutpat commodo sed egestas egestas fringilla. Ac orci phasellus egestas tellus rutrum tellus pellentesque eu. Pellentesque sit amet porttitor eget dolor morbi non arcu risus. Sit amet est placerat in egestas erat. Ipsum nunc aliquet bibendum enim facilisis gravida neque convallis a.

Sed faucibus turpis in eu mi. Mattis molestie a iaculis at erat pellentesque. Vel orci porta non pulvinar. Faucibus scelerisque eleifend donec pretium vulputate. Cras semper auctor neque vitae tempus quam pellentesque nec. In hac habitasse platea dictumst quisque sagittis. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla. Id semper risus in hendrerit gravida rutrum quisque non. Eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque. Ridiculus mus mauris vitae ultricies leo integer malesuada nunc vel. Pulvinar elementum integer enim neque volutpat ac tincidunt. Magna etiam tempor orci eu. Mauris sit amet massa vitae tortor condimentum lacinia quis. Metus aliquam eleifend mi in nulla posuere. Vitae tempus quam pellentesque nec nam aliquam sem. Orci nulla pellentesque dignissim enim sit amet venenatis urna. Tellus in metus vulputate eu scelerisque felis.

Mattis enim ut tellus elementum sagittis. Eu nisl nunc mi ipsum faucibus. Dignissim convallis aenean et tortor at risus viverra. Mauris vitae ultricies leo integer malesuada nunc. Sit amet massa vitae tortor condimentum lacinia quis. Urna cursus eget nunc scelerisque viverra. Dis parturient montes nascetur ridiculus mus mauris. Etiam sit amet nisl purus in mollis nunc sed. Pellentesque sit amet porttitor eget dolor. Curabitur gravida arcu ac tortor dignissim. Vel pretium lectus quam id leo in vitae turpis massa. Malesuada proin libero nunc consequat interdum varius sit amet. Urna neque viverra justo nec. Quis enim lobortis scelerisque fermentum dui.

Eget dolor morbi non arcu. Quam vulputate dignissim suspendisse in est ante in. Massa massa ultricies mi quis hendrerit. Porttitor rhoncus dolor purus non enim. Urna neque viverra justo nec ultrices dui. Etiam sit amet nisl purus in mollis nunc sed. Morbi tincidunt ornare massa eget egestas purus viverra. Quam vulputate dignissim suspendisse in est ante in nibh. Volutpat ac tincidunt vitae semper quis lectus. Morbi tristique senectus et netus et malesuada fames ac turpis. In vitae turpis massa sed elementum. Lacus luctus accumsan tortor posuere. Arcu non sodales neque sodales ut. At augue eget arcu dictum varius duis. Elementum facilisis leo vel fringilla est ullamcorper eget.

Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. At tellus at urna condimentum mattis pellentesque. Semper feugiat nibh sed pulvinar proin gravida hendrerit lectus a. Libero justo laoreet sit amet cursus sit amet dictum. Fermentum leo vel orci porta non pulvinar neque laoreet. Mi eget mauris pharetra et ultrices neque ornare aenean. Blandit aliquam etiam erat velit scelerisque in. Laoreet suspendisse interdum consectetur libero id faucibus nisl. Eget nunc scelerisque viverra mauris in aliquam sem fringilla ut. Magnis dis parturient montes nascetur ridiculus mus. Nec dui nunc mattis enim. Accumsan sit amet nulla facilisi morbi. In vitae turpis massa sed elementum tempus egestas sed. Aliquam malesuada bibendum arcu vitae elementum. Enim eu turpis egestas pretium aenean. Hac habitasse platea dictumst quisque. Massa sed elementum tempus egestas. Leo urna molestie at elementum. Suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Faucibus scelerisque eleifend donec pretium vulputate sapien.

Libero justo laoreet sit amet cursus sit amet dictum. Pellentesque massa placerat duis ultricies lacus sed turpis. Mollis aliquam ut porttitor leo a diam sollicitudin. Imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper. Eget nunc lobortis mattis aliquam. Ac odio tempor orci dapibus ultrices in iaculis. Quis blandit turpis cursus in hac. Bibendum arcu vitae elementum curabitur vitae nunc. Orci porta non pulvinar neque laoreet suspendisse. Cursus euismod quis viverra nibh cras. Eleifend quam adipiscing vitae proin sagittis. Urna id volutpat lacus laoreet non. Sit amet venenatis urna cursus eget nunc scelerisque.

Eu turpis egestas pretium aenean pharetra magna ac placerat vestibulum. Sed egestas egestas fringilla phasellus faucibus. Tristique senectus et netus et malesuada fames ac turpis egestas. Eleifend quam adipiscing vitae proin sagittis nisl rhoncus. Commodo quis imperdiet massa tincidunt. Odio eu feugiat pretium nibh. Vulputate eu scelerisque felis imperdiet proin. Urna porttitor rhoncus dolor purus non. Arcu cursus euismod quis viverra nibh cras pulvinar mattis. Felis eget nunc lobortis mattis. At augue eget arcu dictum varius duis at consectetur. Aliquam etiam erat velit scelerisque in dictum non.

Scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis. Et malesuada fames ac turpis egestas integer eget. Eu feugiat pretium nibh ipsum consequat nisl. Dignissim sodales ut eu sem integer vitae justo eget magna. Et tortor consequat id porta nibh venenatis cras sed. Porttitor rhoncus dolor purus non enim. Sociis natoque penatibus et magnis dis parturient montes nascetur. Odio pellentesque diam volutpat commodo sed egestas. Augue neque gravida in fermentum et sollicitudin ac. Arcu non sodales neque sodales ut etiam sit amet nisl. Malesuada bibendum arcu vitae elementum curabitur. Cursus euismod quis viverra nibh cras pulvinar mattis.

Sollicitudin tempor id eu nisl nunc mi ipsum faucibus vitae. Tellus at urna condimentum mattis pellentesque. Et ligula ullamcorper malesuada proin libero nunc consequat. Nullam eget felis eget nunc lobortis mattis aliquam faucibus. Massa vitae tortor condimentum lacinia quis. Tincidunt id aliquet risus feugiat in. Sit amet purus gravida quis blandit turpis cursus in hac. Aliquam sem fringilla ut morbi tincidunt augue interdum velit euismod. Sit amet volutpat consequat mauris nunc congue. Senectus et netus et malesuada fames ac turpis. Parturient montes nascetur ridiculus mus.

Viverra aliquet eget sit amet tellus cras. Egestas sed tempus urna et pharetra pharetra. Non tellus orci ac auctor. Mattis nunc sed blandit libero volutpat sed. Sapien nec sagittis aliquam malesuada bibendum. Vitae congue eu consequat ac felis donec et odio pellentesque. Eu lobortis elementum nibh tellus molestie nunc. Tincidunt vitae semper quis lectus nulla. Consectetur adipiscing elit duis tristique sollicitudin nibh sit. Fermentum odio eu feugiat pretium nibh ipsum consequat nisl vel. Sit amet porttitor eget dolor morbi non arcu risus quis. Odio ut sem nulla pharetra diam sit amet nisl. In nisl nisi scelerisque eu. Viverra maecenas accumsan lacus vel facilisis volutpat est velit egestas. Diam sit amet nisl suscipit adipiscing bibendum est ultricies integer. Scelerisque fermentum dui faucibus in ornare quam viverra. Eget nunc scelerisque viverra mauris. Lorem mollis aliquam ut porttitor leo a diam sollicitudin tempor.

Semper risus in hendrerit gravida. Feugiat nibh sed pulvinar proin gravida hendrerit lectus a. Netus et malesuada fames ac. Et malesuada fames ac turpis. Aenean vel elit scelerisque mauris. Mattis vulputate enim nulla aliquet. Accumsan in nisl nisi scelerisque eu ultrices vitae. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Dui faucibus in ornare quam. Ut venenatis tellus in metus vulputate eu. Euismod nisi porta lorem mollis.
        </p>
        <asom-button text="Close Slide Menu" @click="showSlider1 = false" />
      </asom-slide-menu>
      <p>You can pass the slider title as a prop</p>
    </div>
  </docs-layout>
</template>

<script>
import DocsLayout from "@/docs/DocsLayout.vue";
export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      showSlider1: false,
    };
  },
  methods: {
    toggleSlider1(value) {
      this.showSlider1 = value;
    },
  },
};
</script>
