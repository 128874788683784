<template>
  <div>
    <docs-layout>
      <asom-q-r-code-scanner @detect="logIt"> </asom-q-r-code-scanner>
      <asom-q-r-code-scanner @detect="logIt">
        <template v-slot:default="slotScoped">
          <asom-button
            text="Custom button"
            @click="openCustomScanner(slotScoped)"
          />
        </template>
      </asom-q-r-code-scanner>
      <h1 class="pt-10">QR Code Reader</h1>
      <h2>Decoded QR Code: {{ code }}</h2>
    </docs-layout>
  </div>
</template>

<script>
import DocsLayout from "@/docs/DocsLayout.vue";
import AsomQRCodeScanner from "../../../components/qr_code_scanner/AsomQRCodeScanner";

export default {
  components: {
    DocsLayout,
    AsomQRCodeScanner,
  },
  data() {
    return {
      code: "",
    };
  },
  methods: {
    logIt(data) {
      this.code = data;
    },
    openCustomScanner({ openScanner }) {
      openScanner();
    },
  },
};
</script>
