<template>
  <div>
    <docs-layout>
      <asom-bar-code-scanner
        @detect="logIt"
      >
      </asom-bar-code-scanner>
      <asom-bar-code-scanner
        @detect="logIt"
      >
        <template v-slot:default="slotScoped">
          <asom-button text="Custom button" @click="openCustomScanner(slotScoped)" />
        </template>
      </asom-bar-code-scanner>
      <h1 class="pt-10">Barcode Reader</h1>
      <h2>Decoded Barcode: {{ code }}</h2>
      <h2>Decode Format: {{ format }}</h2>
    </docs-layout>
    
  </div>
</template>

<script>
import DocsLayout from "@/docs/DocsLayout.vue";
import AsomBarCodeScanner from "../../../components/bar_code_scanner/AsomBarCodeScanner";

export default {
  components: {
    DocsLayout,
    AsomBarCodeScanner,
  },
  data() {
    return {
      detecteds: [],
      code: "",
      format: "",
    };
  },
  methods: {
    logIt(data) {
      this.code = data;
    },
    openCustomScanner({ openScanner }) {
      openScanner();
    }
  },
};
</script>
